'use strict'

const actionReducerCreator = require('@wix/dbsm-common/src/actionReducer')
const actionTypes = require('./actionTypes')

module.exports = actionReducerCreator(
  [],
  new (class {
    [actionTypes.SET_CONNECTED_COMPONENTS](state, action) {
      return action.connectedComponents
    }
  })()
)

module.exports.getConnectedComponents = state => state
