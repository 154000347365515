'use strict'

const get_ = require('lodash/get')
const isEmpty_ = require('lodash/isEmpty')
const includes_ = require('lodash/includes')
const values_ = require('lodash/values')
const { addComponentEventListener, getFieldValue } = require('./helpers')
const baseAdapter = require('./baseAdapter')
const forEach_ = require('lodash/forEach')

const {
  selectCurrentRecord,
  isReadOnly
} = require('../../dataset-controller/rootReducer')

const getValueFieldName = connectionConfig =>
  get_(connectionConfig, 'properties.value.fieldName')

module.exports = ({
  getState,
  datasetApi,
  errorReporter,
  getFieldType,
  applicationCodeZone,
  databindingVerboseReporter
}) => {
  const modified = {}

  const resetComponent = component => {
    if (component.reset) {
      component.reset()
    }
  }

  const syncValidityIndicationAndValue = (
    { component, connectionConfig },
    actions,
    valueFieldChanged = false
  ) => {
    const record = selectCurrentRecord(getState())
    const fieldName = getValueFieldName(connectionConfig)

    const newRecord = actions.isCurrentRecordNew(getState())
    const valueIsInvalid = isEmpty_(getFieldValue(record, fieldName))
    const pristine = actions.isCurrentRecordPristine(getState())

    if (pristine || valueFieldChanged) {
      resetComponent(component)
    }

    if (valueIsInvalid && (!newRecord || valueFieldChanged)) {
      component.updateValidityIndication()
    }
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },

    bindToComponent({ connectionConfig, component }, actions) {
      if (isReadOnly(getState())) {
        return
      }

      const connectedFieldName = getValueFieldName(connectionConfig)

      addComponentEventListener(
        component,
        'onChange',
        () => {
          modified[component.uniqueId] = true
        },
        applicationCodeZone
      )

      datasetApi.onBeforeSave(() => {
        if (modified[component.uniqueId] && component.value.length) {
          return component.startUpload().then(
            uploadedFile => {
              modified[component.uniqueId] = false

              actions.setFieldInCurrentRecordAndSynchronize(
                connectedFieldName,
                uploadedFile.url,
                component.uniqueId
              )
            },
            e => {
              errorReporter(
                `The ${get_(component, 'value[0].name') ||
                  'unknown'} file failed to upload. Please try again later.`,
                e
              )
              throw e
            }
          )
        }
      })

      getFieldType(connectedFieldName).map(fieldType => {
        switch (fieldType) {
          case 'image':
            component.fileType = 'Image'
            break
          case 'document':
            component.fileType = 'Document'
            break
        }
      })

      const { properties } = connectionConfig
      forEach_(properties, ({ fieldName }, propName) => {
        databindingVerboseReporter.logBinding({
          component,
          bindingDescription: {
            [propName]: fieldName
          }
        })
      })
    },

    currentRecordModified(
      { component, connectionConfig },
      actions,
      updatedFields
    ) {
      const fieldName = getValueFieldName(connectionConfig)
      const valueFieldChanged = includes_(updatedFields, fieldName)
      if (valueFieldChanged) {
        modified[component.uniqueId] = false
      }
      syncValidityIndicationAndValue(
        { component, connectionConfig },
        actions,
        valueFieldChanged
      )
    },

    recordSetLoaded(componentAdapterContext, actions) {
      syncValidityIndicationAndValue(componentAdapterContext, actions)
    },

    currentViewChanged(componentAdapterContext, actions) {
      syncValidityIndicationAndValue(componentAdapterContext, actions)
    },

    currentIndexChanged(componentAdapterContext, actions) {
      syncValidityIndicationAndValue(componentAdapterContext, actions)
    }
  }
}
