'use strict'

const { READ } = require('./readWriteModes')
const { DEFAULT_PAGE_SIZE } = require('./pagination')

module.exports = {
  collectionName: null,
  readWriteType: READ,
  filter: null,
  sort: null,
  includes: null,
  pageSize: DEFAULT_PAGE_SIZE
}
